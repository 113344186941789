import React from 'react';

export const myAuctionsCarouselSettings = {
  autoplay: {
    delay: 500000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    type: 'none',
  },
  lazy: true,
  loop: false,
  slidesPerView: 3,
  renderPrevButton: () => (
    <div
      className="swiper-button-prev myAuctionsBtn"
      role="button"
    >
      <i className="fa fa-angle-left fa-4x" />
    </div>
  ),
  renderNextButton: () => (
    <div
      className="swiper-button-next myAuctionsBtn"
      role="button"
    >
      <i className="fa fa-angle-right fa-4x" />
    </div>
  ),
};

export const myAuctionsCarouselMobile = {
  autoplay: {
    delay: 500000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    type: 'none',
  },
  lazy: true,
  loop: true,
  slidesPerView: 1,
  renderPrevButton: () => (
    <div
      className="swiper-button-prev myAuctionsBtn"
      role="button"
    >
      <i className="fa fa-angle-left fa-4x" />
    </div>
  ),
  renderNextButton: () => (
    <div
      className="swiper-button-next myAuctionsBtn"
      role="button"
    >
      <i className="fa fa-angle-right fa-4x" />
    </div>
  ),
};
