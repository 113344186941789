import React from 'react';

const carouselSettings = {
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
  lazy: true,
  loop: true,
  slidesPerView: 1,
  renderPrevButton: () => (
    <div
      className="swiper-button-prev"
      role="button"
    >
      <i className="fa fa-angle-left fa-4x" />
    </div>
  ),
  renderNextButton: () => (
    <div
      className="swiper-button-next"
      role="button"
    >
      <i className="fa fa-angle-right fa-4x" />
    </div>
  ),
};

export default carouselSettings;
