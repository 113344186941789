import React, { useRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthToken } from 'src/selectors/inv-common';
import Swiper from 'react-id-swiper';
import { get } from 'lodash';
import { bool } from 'prop-types';
import { myAuctionsCarouselSettings, myAuctionsCarouselMobile } from '../../components/homepage/myAuctionsCarouselSettings';
import MyAuctionsCard from './MyAuctionCard';

function MyAuctions({ isMobile }) {
  const myAuctions = useSelector(state => state.homepage.myAuctions);
  const xAuthToken = useSelector(selectAuthToken);
  const myAuctionsArr = get(myAuctions, '_embedded.registeredAuctionsViewList', []);
  const swiperRef = useRef(null);

  return (
    // Temporarily hide to accomodate test on pageload
    <div
      id="myAuctionsUnit"
      className="space-top-wide space-bottom-wide"
    >
      { myAuctionsArr.length > 0
      && (
      <Fragment>
        <h2>My Auctions {myAuctionsArr && `(${myAuctionsArr.length})`}&nbsp;
          {myAuctions._embedded.registeredAuctionsViewList.length > 10 && (
          <a
            className="btn-link myAuctions-link"
            href="/my-account/auctions"
          >View All
          </a>
          )}
        </h2>
        <div>
          {!isMobile
          && (
          <Swiper
            {...myAuctionsCarouselSettings}
            ref={swiperRef}
          >
            {/* Only show first 10 upcoming auctions in Carousel */}
            {myAuctionsArr.length > 0 && myAuctionsArr.slice(0, 10).map(auction => (
              <div key={auction.ref}>
                <MyAuctionsCard
                  xAuthToken={xAuthToken}
                  auction={auction}
                />
              </div>
            ))}
          </Swiper>
          )}
          {isMobile
          && (
          <Swiper
            {...myAuctionsCarouselMobile}
            ref={swiperRef}
          >
            {myAuctionsArr.length > 0 && myAuctionsArr.slice(0, 10).map(auction => (
              <div key={auction.ref}>
                <MyAuctionsCard
                  xAuthToken={xAuthToken}
                  auction={auction}
                />
              </div>
            ))}
          </Swiper>
          )}
        </div>
      </Fragment>
      )}
    </div>
  );
}

MyAuctions.propTypes = {
  isMobile: bool.isRequired,
};

export default MyAuctions;
