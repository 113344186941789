import React, { Component } from 'react';
import { connect } from 'react-redux';
import { arrayOf, shape } from 'prop-types';
import { get } from 'lodash';

import HomepageCarousel from '../../components/homepage/HomepageCarousel';
import UpcomingAuction from '../../components/homepage/UpcomingAuction';
import { routes } from '../../../config';
import HomeLoadingSpinner from './HomeLoadingSpinner';
import MyAuctions from './MyAuctions';

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      carouselAdArray: [],
      loading: true,
      isMobile: false,
    };
  }

  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://assets-static-v2.invaluable.com/init-2.min.js';
    script.async = true;
    document.body.appendChild(script);

    window.broadstreet = window.broadstreet || { run: [] };
    window.broadstreet.run.push(() => {
      window.broadstreet.loadNetworkJS(5936, {
        domain: 'assets-v2.invaluable.com',
        useAltZone: 768,
        responsive: true,
      });
    });

    this.checkElemReady();
    // (bnikom) switch to mobile or desktop slides on resize
    window.addEventListener('resize', this.checkElemReady);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkElemReady);
  }

  getAds = (carouselAds, type) => {
    const carouselAdArray = [];
    Object.keys(carouselAds).forEach((ad) => {
      // (bnikom) override broadtstreet ad animation that hides ads - always display: block
      // discard bug in broadstreet ads showing null ad block INV-8438
      if (carouselAds[ad].innerText !== 'null') {
        // eslint-disable-next-line no-param-reassign
        carouselAds[ad].className = 'show';
        carouselAdArray.push({ html: carouselAds[ad].outerHTML, id: carouselAds[ad].id });
      }
    });

    this.setState({ carouselAdArray, type, loading: false });
  };

  /**
   * (bnikom) check if the broadstreet ads are loaded
   * if they are: get each individual asset and populate the carousel with it
   * if not: check again after 50 ms
   * */
  checkElemReady = () => {
    const elem = document.getElementById('adContainer');
    const carouselAds = window.innerWidth < 767
      ? get(elem, 'children[1].children[0].children[0].children', null)
      : get(elem, 'children[0].children[0].children[0].children', null);

    if (carouselAds) {
      const { type } = this.state;
      // (bnikom) only fetch new ads if the screen size change doesn't match the slide type displayed
      if (window.innerWidth < 767 && type !== 'mobile') {
        this.getAds(carouselAds, 'mobile');
        this.setState({ isMobile: true });
      } else if (window.innerWidth >= 768 && type !== 'desktop') {
        this.getAds(carouselAds, 'desktop');
        this.setState({ isMobile: false });
      }
    } else {
      setTimeout(this.checkElemReady, 50);
    }
  };

  render() {
    const { upcomingAuctions, sessionInfo } = this.props;
    const { carouselAdArray, loading, isMobile } = this.state;

    return (
      <section className="homepage-container">
        <div className="carousel-container space-bottom">
          {loading ? <HomeLoadingSpinner /> : <HomepageCarousel carouselAdArray={carouselAdArray} />}
        </div>
        <div className="container-fluid space-top-wide">
          <MyAuctions
            isMobile={isMobile}
          />
          <div
            id="homeZone1"
            className="popular-bp-items space-top-40"
          />

          <div
            id="homeZone2"
            className="space-top-40"
          />

          <div
            id="homeZone3"
            className="space-top-40"
          />
          <div
            id="homeZone4"
            className="space-top-40"
          />
          <div
            id="homeZone5"
            className="space-top-40"
          />
          <div
            id="homeZone6"
            className="space-top-40"
          />
          <div
            id="homeZone7"
            className="space-top-40"
          />

          {/* (bnikom) if the first item has bp parity make the top border the correct style */}
          {upcomingAuctions !== null
          && (
          <div className={`space-top-wide ${upcomingAuctions[0].displayParityBanner ? 'padding-bottom' : 'bordered-bottom'}`}>
            <h1 className="space-bottom-none mt-4">Upcoming Online Auctions</h1>
          </div>
          )}
          <div
            id="UpcomingAuctionsHomepage"
            className="featured-auctions"
          >
            {upcomingAuctions && upcomingAuctions.map(auction => (
              <UpcomingAuction
                key={auction.ref}
                auction={auction}
                isProduction={this.props.isProd}
                xAuthToken={sessionInfo.authToken}
              />
            ))}
          </div>
          <div className="text-center space-top-wide">
            <a href={routes.UPCOMING_AUCTIONS}>View all upcoming live and timed auctions</a>
          </div>
        </div>
        <div
          id="adContainer"
          className="hidden"
        >
          <broadstreet-zone zone-id="69928" />
          <broadstreet-zone alt-zone-id="72248" />
        </div>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/Swiper/4.3.5/css/swiper.min.css"
        />
      </section>
    );
  }
}

Homepage.propTypes = {
  upcomingAuctions: arrayOf(shape({})),
};

Homepage.defaultProps = {
  upcomingAuctions: [],
  myAuctions: {},
};

const mapStateToProps = ({ homepage: { isLoadingUpcomingAuctions, upcomingAuctions, type, isProd }, invReactCommon: { sessionInfo } }) => ({
  isLoadingUpcomingAuctions, upcomingAuctions, type, isProd, sessionInfo
});

export default connect(mapStateToProps)(Homepage);
