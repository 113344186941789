import React from 'react';

import { Carousel, NON_CIRCULAR_CAROUSEL_CONFIG } from '../common/carousel';

export function FeaturedListWrapper({ children, isMobileView }) {
  const commonConfig = {
    centerMode: false,
    infinite: false
  };

  const carouselConfig = {
    ...NON_CIRCULAR_CAROUSEL_CONFIG,
    MOBILE_CONFIG: {
      ...NON_CIRCULAR_CAROUSEL_CONFIG.MOBILE_CONFIG,
      ...commonConfig,
      slidesToShow: 4,

    },
    MOBILE_SM_CONFIG: {
      ...NON_CIRCULAR_CAROUSEL_CONFIG.MOBILE_SM_CONFIG,
      ...commonConfig,
      slidesToShow: 3,

    },
  };

  if (isMobileView) {
    return (
      <div className="carousel-wrapper">
        <Carousel customConfig={carouselConfig}>
          {children}
        </Carousel>
      </div>
    );
  }

  return (
    <div
      className="d-flex w-100 align-content-center"
      style={{ marginTop: '15px' }}
    >
      {children}
    </div>
  );
}
