// eslint-disable-next-line import/prefer-default-export
export const YOTPO_APP_KEYS = {
  PROD: 'GZbTRMDgLU3BznNtmFyLKjckxxZ2MdDee3rtI75p',
  PRE_PROD: 'GZ2RSBZoSJusvxO5Yzx0H7FUYNpP2vXXZfjHYksU',
};

export const SELLER_REVIEWS_CONTAINER_ID = 'seller-reviews';
export const YOTPO_RENDERED_CONTAINER_SELECTOR = '.yotpo-display-wrapper';
export const YOTPO_RATING_SELECTOR = `#${SELLER_REVIEWS_CONTAINER_ID} .yotpo-stars > .sr-only`;
export const MIN_RATING_TO_SHOW_THE_WIDGET = 3.5;
