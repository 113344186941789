import React from 'react';

function HomeLoadingSpinner() {
  return (
    <div
      className="loading-spinner-home"
      id="loaderSlide"
    ><i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw" /><span className="sr-only">Loading...</span>
    </div>
  );
}

export default HomeLoadingSpinner;
