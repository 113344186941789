import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { isClientProdEnv } from '../../../utils/common/helpers';
import {
  YOTPO_APP_KEYS,
  YOTPO_RENDERED_CONTAINER_SELECTOR,
  YOTPO_RATING_SELECTOR,
  MIN_RATING_TO_SHOW_THE_WIDGET,
  SELLER_REVIEWS_CONTAINER_ID,
} from './constants';

function SellerReviews({ sellerRef, sellerProfileLink, minRatingThreshold, showRatingText, itemKey }) {
  const [sellerRating, setSellerRating] = useState(0.0);

  useEffect(() => {
    const appKey = isClientProdEnv() ? YOTPO_APP_KEYS.PROD : YOTPO_APP_KEYS.PRE_PROD;
    const yotpoScriptSrc = `//staticw2.yotpo.com/${appKey}/widget.js`;
    const isYotpoScriptAlreadyAdded = document.querySelector(`head > script[src='${yotpoScriptSrc}']`);

    if (!isYotpoScriptAlreadyAdded) {
      const yotpoScript = document.createElement('script');
      yotpoScript.src = yotpoScriptSrc;
      yotpoScript.async = true;

      document.head.appendChild(yotpoScript);
    }
  }, []);

  useEffect(() => {
    window?.yotpo?.refreshWidgets();
  }, [itemKey]);

  const getUpdatedRating = (ratingText) => (ratingText ? ratingText.split(' ')[0] : '0.0');

  const waitForWidgetAndSetRating = () => {
    if (!document.querySelector(YOTPO_RENDERED_CONTAINER_SELECTOR)) {
      setTimeout(waitForWidgetAndSetRating, 1000);
      return;
    }

    // YOTPO_RATING_SELECTOR element contains a text that contains rating, e.g, "3.0 star rating"
    let ratingText = document.querySelector(YOTPO_RATING_SELECTOR)?.innerText;
    let rating = getUpdatedRating(ratingText);

    // show rating text with stars and add brackets around reviews
    if (showRatingText) {
      let reviewsElement = document.querySelector('div.yotpo-bottomline.pull-left.star-clickable > a');
      // if there's a list fetch the element based on its id.
      if (itemKey) {
        const currentListElement = document.getElementById(itemKey);
        reviewsElement = currentListElement?.querySelector('a');

        ratingText = currentListElement?.querySelector(YOTPO_RATING_SELECTOR)?.innerText;
        rating = getUpdatedRating(ratingText);
      }
      if (reviewsElement) {
        reviewsElement.innerText = `(${reviewsElement.innerHTML})`;
        const ratingSpan = document.createElement('span');
        if (rating) {
          ratingSpan.innerText = `\u00A0${rating}\u00A0`;
          ratingSpan.classList.add('avg-score-label');
          reviewsElement.before(ratingSpan);
        }
      }
    }
    setSellerRating(Number(rating));
  };

  useEffect(() => {
    waitForWidgetAndSetRating();
  }, []);

  const minDisplayedRating = minRatingThreshold || MIN_RATING_TO_SHOW_THE_WIDGET;
  const isWidgetHidden = sellerRating < minDisplayedRating;

  return (
    <div
      id={SELLER_REVIEWS_CONTAINER_ID}
      className="mt-2 mb-2 review-widget-holder"
    >
      <a
        id={itemKey}
        href={`/${sellerProfileLink}`}
        target="_blank"
        rel="noreferrer"
        className={classNames('review-star-widget', { 'd-none': isWidgetHidden }, { 'section-hidden': isWidgetHidden })}
      >
        <div
          className="yotpo bottomLine"
          data-product-id={sellerRef}
        />
      </a>
    </div>
  );
}

SellerReviews.propTypes = {
  sellerRef: PropTypes.string.isRequired,
  sellerProfileLink: PropTypes.string.isRequired,
  minRatingThreshold: PropTypes.number,
  showRatingText: PropTypes.bool,
  itemKey: PropTypes.string
};

SellerReviews.defaultProps = {
  minRatingThreshold: 3.5,
  showRatingText: false,
  itemKey: null,
};

export default SellerReviews;
