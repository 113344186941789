/* eslint-disable react/no-danger */
import React, { Fragment, useState, useRef } from 'react';
import { arrayOf, shape } from 'prop-types';
import Swiper from 'react-id-swiper';
import { get } from 'lodash';

import carouselSettings from './carouselSettings';

/**
 * (bnikom) i know ppl. are gonna be thrown off by ~~dangerouslySetInnerHTML~~
 * but it's because of that's an easy way to inadvertently expose your users
 * to cross-site scripting (XSS) attack. As long as you trust where you're markup is coming from
 * you're good. And if our markup is contaminated it's Marketing/BroadstreetAds fault.
 * https://reactjs.org/docs/dom-elements.html
 * https://stackoverflow.com/questions/46832912/is-dangerouslysetinnerhtml-the-only-way-to-render-html-from-an-api-in-react
 * ^^^^^^^^^
 * I like this explanation
 */
function HomepageCarousel({ carouselAdArray }) {
  const [isPaused, setPause] = useState(false);
  const swiperRef = useRef(null);

  const autoPlay = () => {
    const swiper = get(swiperRef, 'current.swiper', null);
    if (isPaused && swiper) {
      swiper.autoplay.start();
      setPause(!isPaused);
    } else if (!isPaused && swiper) {
      swiper.autoplay.stop();
      setPause(!isPaused);
    }
  };

  return (
    <Fragment>
      {carouselAdArray.length > 0
        && (
        <div className="position-relative">
          <Swiper
            {...carouselSettings}
            ref={swiperRef}
          >
            {carouselAdArray.map(ad => (
              <div
                key={ad.id}
              >
                <div
                  className="swiper-lazy"
                  dangerouslySetInnerHTML={{ __html: ad.html }}
                />
                <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
              </div>
            ))}
          </Swiper>
          <div className="carousel-play-pause-container">
            <button
              className="play-pause-btn"
              onClick={autoPlay}
              aria-label={`${isPaused ? 'play' : 'pause'} carousel`}
            >
              {isPaused
                ? <i className="fa fa-play" />
                : <i className="fa fa-pause" />}
            </button>
          </div>
        </div>
        )}
    </Fragment>
  );
}

HomepageCarousel.propTypes = {
  carouselAdArray: arrayOf(shape({})),
};

HomepageCarousel.defaultProps = {
  carouselAdArray: [],
};

export default HomepageCarousel;
